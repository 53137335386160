$(function() {

    var formData = new FormData();

    if ($('.dragload-file').length !== 0) {

        let dropArea = $('.dragload-file')[0];

        // убираем стандартно поведение перетаскивания по умолчанию
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(function(eventName){
            dropArea.addEventListener(eventName, preventDefaults, false);
            document.body.addEventListener(eventName, preventDefaults, false);
        });

        // Выделение области при перетаскивани
        ['dragenter', 'dragover'].forEach(function(eventName){
            dropArea.addEventListener(eventName, highlight, false);
        });

        // Выделение области после перетаскивани
        ['dragleave', 'drop'].forEach(function(eventName){
            dropArea.addEventListener(eventName, unhighlight, false);
        });

        // Обработка закинутых файлов
        dropArea.addEventListener('drop', handleDrop, false);

        function preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        }

        function highlight(e) {
            dropArea.classList.add('highlight');
        }

        function unhighlight(e) {
            dropArea.classList.remove('highlight');
        }

        function handleDrop(e) {
            var dt = e.dataTransfer;
            var files = dt.files;

            projectFile = files;

            handleFiles(files);
        }

        function handleFiles(files) {
            files = Array.prototype.slice.call(files);
            files.forEach(uploadFile);
            files.forEach(previewFile);
        }

        // Предварительный просмотр файла
        function previewFile(file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                $('.dragload-file label').html(file.name);
                /* let img = document.createElement('img');
                 img.src = reader.result;
                 document.getElementById('gallery').appendChild(img);*/
            }
        }

        // загрузка файла на сервер
        function uploadFile(file, i) {
            /*  var url = '';
              var xhr = new XMLHttpRequest();
              var formData = new FormData();
              xhr.open('POST', url, true);
              xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');


              xhr.addEventListener('readystatechange', function(e) {
                 if (xhr.readyState == 4 && xhr.status != 200) {
                      // Error.
                  }
              });*/

              formData.append('file', file);
        }

        // загрузка файла через инпут
        $('.dragload-file input').on('change', function (e) {
            let fullPath = $(this)[0].value;
            projectFile = this.files[0];
            if (fullPath) {
                let startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
                let filename = fullPath.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }
                $('.dragload-file label').html(filename);
                formData.append('file',projectFile);
            }
        });

    }

    $('.contact-s__form').on('submit', function (e){
        e.preventDefault();

        $(this).serializeArray().forEach(function(e){
            formData.append(e.name, e.value);
        });





        $.ajax({
            url: 'mail.php',
            type: 'post',
            processData: false,
            contentType: false,
            data: formData,
            success: function(){
                $.notify("Ваша заявка принята", "success");
                $('.contact-s__form')[0].reset();
                formData = new FormData();
                $('.contact-s__form-send button').attr('disabled','');

                $('.dragload-file label').html('<label for="dragloadfile">\n' +
                    '                      <div><svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path fill-rule="evenodd" clip-rule="evenodd" d="M11.8835 9.5V11H3.84466C1.71961 11 0 8.54 0 5.5C0 2.46 1.71961 0 3.84466 0L12.5825 0C14.1274 0 15.3786 1.79 15.3786 4C15.3786 6.21 14.1274 8 12.5825 8H5.24272C4.27806 8 3.49515 6.88 3.49515 5.5C3.49515 4.12 4.27806 3 5.24272 3H11.8835V4.5H5.24272C4.85825 4.5 4.54369 4.95 4.54369 5.5C4.54369 6.05 4.85825 6.5 5.24272 6.5H12.5825C13.5472 6.5 14.3301 5.38 14.3301 4C14.3301 2.62 13.5472 1.5 12.5825 1.5H3.84466C2.29981 1.5 1.04854 3.29 1.04854 5.5C1.04854 7.71 2.29981 9.5 3.84466 9.5H11.8835Z" fill="white"></path>\n' +
                    '</svg>\n' +
                    '                      </div><span>Нажмите или перетащите файл сюда для начала загрузки</span>\n' +
                    '                    </label>');
            }
        });
    });

    $('.contact-s__form-check input').on('change', function(){
        if($(this).prop('checked') === true){
            $('.contact-s__form-send button').removeAttr('disabled');
        }else{
            $('.contact-s__form-send button').attr('disabled','');
        }
    });
});
