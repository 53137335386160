$(function() {

    $('.hero-s__call').on('click', function(){
        paginationFlag = true;

        $('.header__nav a').removeClass('active').eq(3).addClass('active');

        setTimeout(function(){
            paginationFlag = false;
        },700);
    });
});
