$(function() {


    /*var mySwiper = new Swiper('.serv-s__box', {
        speed: 400,
        spaceBetween: 100,
        direction: 'vertical',
        pagination: {
            el: '.serv-s__tools-dots',
            clickable: 'true'
        },
        navigation: {
            prevEl: '.serv-s__tools-arrow.prev',
            nextEl: '.serv-s__tools-arrow.next',
        },
        slidesPerView: 1,
        breakpoints: {

            320: {
                preventInteractionOnTransition: true,
                noSwiping: true,
                allowTouchMove: false
            },
            1280: {
                preventInteractionOnTransition: false,
                noSwiping: false,
                allowTouchMove: true
            }
        }
    });

    var mySwiper2 = new Swiper('.serv-s__slider', {
        speed: 400,
        spaceBetween: 15,
        slidesPerView: 1,
        pagination: {
            el: '.serv-s__pagination',
            clickable: 'true'
        },
        autoplay: {
            delay: 3500,
            waitForTransition: false,
            disableOnInteraction: false,
        }
    });*/
});
