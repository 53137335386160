$(function() {


    let aboutSwiper = new Swiper('.about-s__slider', {
        speed: 400,
        effect: 'fade',
        slidesPerView: 1,
        on: {
            slideChange: function (e) {
                $('.about-s__nav a').removeClass('active');
                $('.about-s__nav a').eq(aboutSwiper.activeIndex).addClass('active');
            },
        },
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: '.about-s__slider-pagination',
            type: 'progressbar',
        },

        breakpoints: {

            320: {
                pagination: {
                    el: '.about-s__slider-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            },

            767: {
                pagination: {
                    el: '.about-s__slider-pagination',
                    type: 'progressbar',
                },
            }
        }
    });

    $('.about-s__nav a').on('click', function(e){
        e.preventDefault();
        aboutSwiper.slideTo($(this).data('goto'));
    });

    $(window).resize(function(){
        if($(this).width()>1280){
            $('.about-s__slider-el').mCustomScrollbar(); //apply scrollbar with your options
        }else{
            $('.about-s__slider-el').mCustomScrollbar("destroy"); //destroy scrollbar
        }
    }).trigger("resize");

});
