$(function() {

    $('.header__nav a').on('click', function(){
        paginationFlag = true;

        $('.header__nav a').removeClass('active');
        $(this).addClass('active');

        setTimeout(function(){
            paginationFlag = false;
        },700);
    });

    $('.header__logo').on('click', function(){
        paginationFlag = true;

        $('.header__nav a').removeClass('active');

        setTimeout(function(){
            paginationFlag = false;
        },700);
    });
});
