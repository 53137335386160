/*$("input[type='tel']").mask("+7 ( 9 9 9 ) 9 9 9 – 9 9 – 9 9").on('click', function () {
    if ($(this).val() === '+7 (___) ___-__-__') {
        $(this).get(0).setSelectionRange(4, 4);
    }
});*/

var paginationFlag = false;

$(function() {



    $('#collab-room').fullpage({
        autoScrolling:true,
        normalScrollElements: '.about-s__slider-el',
        responsiveWidth: 1280,
        afterLoad: function(hz,origin){

        },
        onLeave: function(origin, destination, direction){
            if(paginationFlag === false){
                $('.header__nav a').removeClass('active');
                if(direction === 'down'){
                    if((origin-1) >= 2){
                        $('.header__nav a').eq(origin-2).addClass('active');
                    }else{
                        $('.header__nav a').eq(origin-1).addClass('active');
                    }

                }else{

                    if(origin-3 >= 0){
                        if(origin-3 >= 2){
                            $('.header__nav a').eq(origin-4).addClass('active');
                        }else {
                            $('.header__nav a').eq(origin-3).addClass('active');
                        }

                    }

                }
            }
        }
    });

});

function circleAnimPercent(that){

    let $this = that,
        $circle = $this.find('.bar'),
        nowVaL = $this.data('percent');

    if (isNaN(nowVaL)) {
        nowVaL = 100;
    }

    let r = 53;
    let c = Math.PI*(r*2);

    if (nowVaL < 0) { nowVaL = 0;}
    if (nowVaL > 100) { nowVaL = 100;}



    let pct = ((100-nowVaL)/100)*c;

    $circle.css({ strokeDashoffset: pct});
}
